<template>
  <v-sheet>
    <v-sheet id="IntroBanner" width="100%" class="mx-auto">
      <p class="text-center text-h5 text-md-h4 font-weight-bold white--text">
        주요기능
      </p>
      <v-tabs style="position: absolute; bottom: 10px" background-color="transparent"
        next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" centered show-arrows
        color="white" dark v-model="tabIndex">
        <v-tabs-slider color="#EFEBE9"></v-tabs-slider>
        <v-tab color="#EFEBE9" to="/main/introduce">
          <label style="color: white; font-weight: bolder; cursor: pointer">주요기능</label>
        </v-tab>
        <!--                <v-tab
                    color="#EFEBE9"
                    to="/main/introduce/order"
                >
                  <label style="color: white;font-weight: bolder;cursor:pointer;">주문서관리</label>
                </v-tab>
                <v-tab
                    color="#EFEBE9"
                    to="/main/introduce/pay"
                >
                  <label style="color: white;font-weight: bolder;cursor:pointer;">결제관리</label>
                </v-tab>
                <v-tab
                    color="#EFEBE9"
                    to="/main/introduce/member"
                >
                  <label style="color: white;font-weight: bolder;cursor:pointer;">회원관리</label>
                </v-tab>-->
      </v-tabs>
    </v-sheet>
  </v-sheet>
</template>
<script>
export default {
  data() {
    return {
      tabIndex: 0,
    };
  },
};
</script>
<style>
#IntroBanner {
  background-image: url("../../../../assets/banner/Introduce.png");
  background-position: center;
  height: 288px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media screen and (max-width:1312px) {
  #IntroBanner {
    padding-top: 6.098vw;
  }
}

@media screen and (max-width:650px) {
  #IntroBanner {
    margin-top: 63px;
    padding-top: 0;
    height: 164px;
  }
}
</style>