<template>
  <section class="max-w-1200-wrap" ref="container">
    <div class="max-w-1200">
      <div class="heading-wrap">
        <div class="heading">
          <h3>
            <strong>무료로 제공</strong>되는<br />
            SNS 결제 플랫폼!
          </h3>
          <p>
            위셀페이는 다양한 SNS에서 상품 판매가 가능한 결제 서비스입니다.<br />
            판매자에게 주문, 배송 관리 시스템이 무료로 제공됩니다.<br />
            별도의 구축 과정이 없어 빠르고 간편합니다.
          </p>
        </div>
        <ul>
          <li>
            <span
              ><img
                src="../../../assets/home-new/sns-1.png"
                alt=""
                loading="lazy"
            /></span>
            <h4>어떤 방식의 판매도</h4>
            <p>셀러샵, SNS판매, SNS셀러 판매</p>
          </li>
          <li>
            <span
              ><img
                src="../../../assets/home-new/sns-2.png"
                alt=""
                loading="lazy"
            /></span>
            <h4>상품정보 URL을 보내면</h4>
            <p>상품정보 페이지 URL</p>
          </li>
          <li>
            <span
              ><img
                src="../../../assets/home-new/sns-3.png"
                alt=""
                loading="lazy"
            /></span>
            <h4>고객들도 간편구매가 가능</h4>
            <p>카드결제, 간편결제, 무통장입금</p>
          </li>
        </ul>
      </div>
      <div class="content">
        <img
          src="../../../assets/home-new/sns-img-1.png"
          alt=""
          loading="lazy"
        />
        <img
          src="../../../assets/home-new/sns-img-2.png"
          alt=""
          loading="lazy"
        />
        <p>
          상품을 등록하시면 상품 URL이 생성되어<br class="hidden-lg" />빠르고
          간편하게 공유하실 수 있습니다.
        </p>
      </div>
    </div>
  </section>
</template>
<script>
import { gsap } from "gsap";
export default {
  mounted() {
    gsap.to(this.$refs.container.querySelectorAll("ul li"), {
      scrollTrigger: {
        trigger: this.$refs.container,
        toggleActions: "play none none reset",
      },
      opacity: 1,
      y: 0,
      duration: 0.5,
      delay: 0.3,
      stagger: 0.3,
    });
  },
};
</script>

<style scoped lang="scss">
.renew {
  .max-w-1200-wrap {
    background-color: #64b1b1;
    padding-top: 133px;
    padding-bottom: 92px;
    overflow: hidden;
  }

  .max-w-1200 .heading h3 {
    color: #f6f6f6;
    font-size: 48px;
    line-height: 55px;
    font-weight: 300;
  }

  .max-w-1200 .heading p {
    color: #f6f6f6;
    font-size: 20px;
    margin-top: 16px;
  }

  .heading-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 64px;
    max-width: calc(100vw - 152px - 130px);
  }

  .heading-wrap ul {
    display: flex;
    gap: 40px;
    margin-right: -132px;
    margin-left: 37px;
    flex-shrink: 0;
  }

  .heading-wrap ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transform: translateY(50px);
  }

  .heading-wrap ul li span {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    width: 160px;
    background: rgba(255, 255, 255, 0.27);
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.045);
    border-radius: 50%;
  }

  .heading-wrap ul li:not(:last-child) span:after {
    content: "";
    position: absolute;
    height: 48px;
    width: 48px;
    top: 0;
    right: -67px;
    bottom: 0;
    margin: auto;
    background-image: url("../../../assets/home-new/chevron-right.svg");
    background-size: cover;
  }

  .max-w-1200 img {
    height: auto;
  }

  .heading-wrap ul li:first-child img {
    width: 107px;
  }

  .heading-wrap ul li:nth-child(2) img {
    width: 55px;
    margin-top: -11px;
  }

  .heading-wrap ul li:nth-child(3) img {
    width: 86px;
  }

  .heading-wrap ul li h4 {
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 4px;
    text-align: center;
    color: #fff;
  }

  .heading-wrap ul li p {
    font-size: 16px;
    text-align: center;
    color: #fff;
  }

  .content {
    display: grid;
    grid-template-areas:
      "img-1 img-2"
      "p p";
    column-gap: 23px;
    row-gap: -8px;
  }

  .content img:first-child {
    width: 744px;
    grid-area: img-1;
    margin-left: -37px;
  }

  .content img:nth-child(2) {
    width: 451px;
    grid-area: img-2;
    margin-right: 19px;
  }

  .content p {
    font-size: 20px;
    font-weight: 700;
    color: #f6ffff;
    grid-area: p;
    margin-left: 10px;
  }

  @media screen and (max-width: 1312px) {
    .max-w-1200-wrap {
      padding-top: 10.137vw;
      padding-bottom: 7.012vw;
    }

    .max-w-1200 .heading h3 {
      font-size: 3.659vw;
      line-height: 4.192vw;
    }

    .max-w-1200 .heading p {
      font-size: 1.524vw;
      margin-top: 1.22vw;
    }

    .heading-wrap {
      margin-bottom: 4.878vw;
      max-width: calc(100vw - 15vw);
    }

    .heading-wrap ul {
      gap: 3.049vw;
      margin-right: -10.061vw;
      margin-left: 2.82vw;
    }

    .heading-wrap ul li span {
      height: 12.195vw;
      width: 12.195vw;
    }

    .heading-wrap ul li:not(:last-child) span:after {
      height: 3.659vw;
      width: 3.659vw;
      right: -5.107vw;
    }

    .heading-wrap ul li:first-child img {
      width: 8.155vw;
    }

    .heading-wrap ul li:nth-child(2) img {
      width: 4.192vw;
      margin-top: -0.838vw;
    }

    .heading-wrap ul li:nth-child(3) img {
      width: 6.555vw;
    }

    .heading-wrap ul li h4 {
      font-size: 1.524vw;
      margin-top: 3.049vw;
      margin-bottom: 0.305vw;
    }

    .heading-wrap ul li p {
      font-size: 1.22vw;
    }

    .content {
      column-gap: 1.753vw;
      row-gap: -0.61vw;
    }

    .content img:first-child {
      width: 56.707vw;
      margin-left: -2.82vw;
    }

    .content img:nth-child(2) {
      width: 34.375vw;
      margin-right: 34.375vw;
    }

    .content p {
      font-size: 1.524vw;
      margin-top: -0.305vw;
      margin-left: 0.762vw;
    }
  }

  @media screen and (max-width: 650px) {
    .max-w-1200-wrap {
      padding-top: 16vw;
      padding-bottom: 21.333vw;
    }

    .max-w-1200 .heading h3 {
      font-size: 6.4vw;
      line-height: 7.467vw;
    }

    .max-w-1200 .heading p {
      font-size: 3.2vw;
      margin-top: 3.2vw;
    }

    .max-w-1200 .heading p br:first-of-type {
      display: none;
    }

    .heading-wrap {
      margin-bottom: 13.867vw;
      flex-direction: column;
      max-width: initial;
      gap: 10.667vw;
    }

    .heading-wrap ul {
      flex-direction: column;
      gap: 21.333vw;
      margin-right: 0;
      margin-left: 0;
    }

    .heading-wrap ul li {
      position: relative;
    }

    .heading-wrap ul li:not(:last-child):after {
      content: "";
      position: absolute;
      height: 12.8vw;
      width: 12.8vw;
      left: 0;
      right: 0;
      bottom: -17.067vw;
      margin: auto;
      background-image: url("../../../assets/home-new/chevron-right.svg");
      background-size: cover;
      transform: rotateZ(90deg);
    }

    .heading-wrap ul li span {
      height: 32vw;
      width: 32vw;
    }

    .heading-wrap ul li:not(:last-child) span:after {
      content: none;
    }

    .heading-wrap ul li:first-child img {
      width: 21.4vw;
    }

    .heading-wrap ul li:nth-child(2) img {
      width: 11vw;
      margin-top: 0;
    }

    .heading-wrap ul li:nth-child(3) img {
      width: 17.2vw;
    }

    .heading-wrap ul li h4 {
      font-size: 5.333vw;
      margin-top: 5.333vw;
      margin-bottom: 1.067vw;
    }

    .heading-wrap ul li p {
      font-size: 4.267vw;
    }

    .content {
      grid-template-areas:
        "img-1"
        "img-2"
        "p";
      /* justify-content: center;
    align-items: center; */
      column-gap: 0;
      row-gap: 0;
    }

    .content img:first-child {
      width: 79.36vw;
      margin-left: 0;
      margin: auto;
    }

    .content img:nth-child(2) {
      width: 84.173vw;
      margin-right: 0;
      margin: auto;
      margin-top: 12.533vw;
    }

    .content p {
      font-size: 4.267vw;
      margin-top: 9.707vw;
      margin-left: 0;
      text-align: center;
    }
  }
}
</style>