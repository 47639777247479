<template>
  <div class="renew">
    <header class="header max-w-1200-wrap"
      :class="{ 'fixed': fixed, 'header-white': white, 'is-top': windowTop }">
      <div class="header__inner max-w-1200">
        <h1 aria-label="wesel pay">
          <router-link to="/">
            <img class="logo logo-white" src="@/assets/logo-new-white.svg" alt="" />
            <img class="logo" src="@/assets/logo-new.svg" alt="" />
          </router-link>
        </h1>
        <nav>
          <ul>
            <li><router-link to="/main/introduce">주요기능</router-link></li>
            <li><router-link to="/main/cost">이용요금</router-link></li>
            <li><router-link to="/main/guide">가입안내</router-link></li>
            <li><router-link to="/event">이벤트</router-link></li>
            <li v-if="$store.state.authenticated">
              <a href="" @click="goToSellerHome">관리자</a>
            </li>
            <li v-if="$store.state.authenticated">
              <router-link to="/auth/logout">로그아웃</router-link>
            </li>
            <li v-if="!$store.state.authenticated">
              <router-link to="/auth/login">로그인</router-link>
            </li>
            <li v-if="!$store.state.authenticated">
              <router-link to="/join/agree">회원가입</router-link>
            </li>
          </ul>
        </nav>
        <button @click="toggleNav" class="menu-btn" type="button">
          <img src="@/assets/menu.svg" alt="" />
        </button>
      </div>
      <div class="nav-overlay" :class="{ 'is-open': navOpen }">
        <nav>
          <ul>
            <li><router-link to="/main/introduce" @click.native="closeNav">주요기능</router-link></li>
            <li><router-link to="/main/cost" @click.native="closeNav">이용요금</router-link></li>
            <li><router-link to="/main/guide" @click.native="closeNav">가입안내</router-link></li>
            <li><router-link to="/event" @click.native="closeNav">이벤트</router-link></li>
            <li v-if="$store.state.authenticated">
              <a href="">관리자</a>
            </li>
            <li v-if="$store.state.authenticated">
              <router-link to="/auth/logout" @click.native="closeNav">로그아웃</router-link>
            </li>
            <li v-if="!$store.state.authenticated">
              <router-link to="/auth/login" @click.native="closeNav">로그인</router-link>
            </li>
            <li v-if="!$store.state.authenticated">
              <router-link to="/join/agree" @click.native="closeNav">회원가입</router-link>
            </li>
          </ul>
        </nav>
        <div @click="closeNav"></div>
      </div>
    </header>
    <IntroHeader v-if="
      [
        '/main/introduce',
        '/main/introduce/order',
        '/main/introduce/pay',
        '/main/introduce/member',
      ].includes($route.path)
    " />
    <CostHeader v-if="$route.path == '/main/cost'" />
    <GuideHeader v-if="$route.path == '/main/guide'" />
    <CsHeader v-if="
      $route.path == '/main/cs' ||
      $route.path == '/main/cs/read' ||
      $route.path == '/main/qa/post' ||
      $route.path == '/main/qa/read' ||
      $route.path == '/main/qa/modify'
    " />
    <AuthHeader v-if="$route.path.startsWith('/auth')" />
    <JoinHeader v-if="$route.path.startsWith('/join')" />
    <RulesHeader v-if="$route.path.startsWith('/policy')" />
  </div>
</template>
<script>
import IntroHeader from "./header/banner/Introduce";
import CostHeader from "./header/banner/Cost";
import GuideHeader from "./header/banner/Guide";
import CsHeader from "./header/banner/Cs";
import AuthHeader from "./header/banner/Auth";
import JoinHeader from "./header/banner/Join";
import RulesHeader from "./header/banner/Rules";
import { SessionToken } from "@/lib/SessionManager";
import { AuthManager, goForLogin } from "@/lib/AuthManager";

export default {
  name: "Header",
  components: {
    IntroHeader,
    CostHeader,
    GuideHeader,
    CsHeader,
    AuthHeader,
    JoinHeader,
    RulesHeader,
  },
  props: {
    white: Boolean,
    fixed: Boolean,
  },
  data() {
    return {
      windowTop: true,
      navOpen: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll(e) {
      this.windowTop = window.top.scrollY < 80;
    },
    async goToSellerHome(e) {
      e.preventDefault();
      if (SessionToken.isLogin()) {
        const ret = await AuthManager.validateAuth();
        if (ret == false) {
          console.log("로그인 페이지로 이동");
          SessionToken.clear();
          goForLogin();
        } else {
          window.location.href = process.env.VUE_APP_SELLER_HOST;
        }
      } else {
        this.$router.push({ name: "home" });
      }
    },
    toggleNav() {
      if (!this.navOpen) {
        document.querySelector(".nav-overlay").style.display = "block";
        setTimeout(() => {
          this.navOpen = true;
        }, 100);
      } else {
        this.navOpen = false;
        setTimeout(() => {
          document.querySelector(".nav-overlay").style.display = "none";
        }, 300);
      }
    },
    closeNav() {
      this.navOpen = false;
      setTimeout(() => {
        document.querySelector(".nav-overlay").style.display = "none";
      }, 300);
    },
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  border-bottom: 1px solid #dbe7ee;
  z-index: 10;
  transition: background-color 0.3s;
}

.header.fixed {
  background-color: #fff;
}

.header h1,
.header h1 a {
  display: inline-flex;
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.logo {
  width: 140px;
  height: auto;
}

.logo-white {
  display: none;
}

.header-white .logo-white {
  display: block;
}

.header-white .logo:not(.logo-white) {
  display: none;
}

.header__inner nav ul {
  display: flex;
  gap: 54px;
  font-size: 18px;
}

.header__inner nav ul li a {
  color: #424242;
  padding: 10px 16px;
  border-radius: 4px;
  transition: 0.3s;
}

.header__inner nav ul li a:hover {
  background-color: rgba(66, 66, 66, 0.08);
}

.header-white .header__inner nav ul li a {
  color: #fff;
}

.header-white .header__inner nav ul li a:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.menu-btn {
  display: none;
  background: transparent;
  cursor: pointer;
}

.nav-overlay {
  display: none;
}

@media screen and (min-width: 651px) {
  .header:not(.is-top) {
    background-color: #fff;
  }

  .header:not(.is-top) .header__inner nav ul li a {
    color: #424242;
  }

  .header:not(.is-top) .header__inner nav ul li a:hover {
    background-color: rgba(66, 66, 66, 0.08);
  }

  .header:not(.is-top) .logo-white {
    display: none;
  }

  .header:not(.is-top) .logo:not(.logo-white) {
    display: block;
  }
}

@media screen and (max-width: 1312px) {
  .header__inner {
    height: 6.098vw;
  }

  .header__inner nav ul li a {
    padding: 0.762vw 1.22vw;
    border-radius: 0.305vw;
  }

  .logo {
    width: 10.6707vw;
  }

  .header__inner nav ul {
    display: flex;
    gap: 4.1158vw;
    font-size: 1.372vw;
  }
}

@media screen and (max-width: 650px) {
  .header {
    position: fixed;
    background-color: #fff;
  }

  .nav-overlay {
    position: absolute;
    overflow: hidden;
    top: 63px;
    left: 0;
    width: 100%;
    height: calc(100vh - 63px);
  }

  .nav-overlay nav {
    padding-bottom: 3.2vw;
    background-color: #fff;
    box-shadow: 0 8px 15px 0 rgb(0 0 0 / 10%);
    width: 100%;
    transform: translateY(-100%);
    transition: 0.3s;
    position: relative;
    z-index: 1;
  }

  .nav-overlay>div {
    position: absolute;
    height: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .is-open nav {
    transform: translateY(0);
  }

  .nav-overlay nav ul li {
    display: flex;
  }

  .nav-overlay nav ul li a {
    width: 100%;
    margin: 0 4.267vw;
    padding: 2.667vw 0;
    color: #424242;
    font-size: 3.2vw;
  }

  .header__inner {
    height: 63px;
  }

  .header__inner nav {
    display: none;
  }

  .logo-white {
    display: none !important;
  }

  .logo {
    width: 130px;
  }

  .logo:not(.logo-white) {
    display: block !important;
  }

  .menu-btn {
    display: inline-flex;
    width: 24px;
    height: 24px;
  }
}
</style>