import { render, staticRenderFns } from "./HomeSection3.vue?vue&type=template&id=889f4800&scoped=true&"
import script from "./HomeSection3.vue?vue&type=script&lang=js&"
export * from "./HomeSection3.vue?vue&type=script&lang=js&"
import style0 from "./HomeSection3.vue?vue&type=style&index=0&id=889f4800&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "889f4800",
  null
  
)

export default component.exports