<template>
  <section class="section" ref="container">
    <v-carousel
      v-model="model"
      :show-arrows="false"
      hide-delimiters
      height="auto"
      @change="onSlide"
      cycle
      :interval="8000"
    >
      <v-carousel-item>
        <div class="max-w-1200-wrap slide-1-wrap">
          <div class="max-w-1200">
            <div class="slide slide-1">
              <div>
                <h2 class="slide-title">
                  <strong>0원</strong>으로 쇼핑몰 창업부터<br />
                  <strong>저렴한 수수료</strong>로<br />
                  판매 수익 증대
                </h2>
                <p class="slide-desc">
                  무료 쇼핑몰 제공, 업계 최저 수수료 혜택까지<br />
                  위셀페이에선 가입비도, 연회비도, PG 가입비도 무료<br />
                  최고의 우대 조건으로 시작하세요!
                </p>
              </div>
              <ul class="grid">
                <li>
                  <img
                    src="../../../assets/home-new/credit-card-02.svg"
                    alt=""
                    loading="lazy"
                  />
                  <p>카드결제<br />수수료</p>
                  <span><strong>3.0</strong>%</span>
                </li>
                <li>
                  <img
                    src="../../../assets/home-new/gift-01.svg"
                    alt=""
                    loading="lazy"
                  />
                  <p>이용료</p>
                  <span>전액<br />무료</span>
                </li>
                <li>
                  <img
                    src="../../../assets/home-new/message-text-square-02.svg"
                    alt=""
                    loading="lazy"
                  />
                  <p>SMS문자</p>
                  <span>무료<br />제공</span>
                </li>
                <li>
                  <img
                    src="../../../assets/home-new/coins-hand.svg"
                    alt=""
                    loading="lazy"
                  />
                  <p>보증보험금</p>
                  <span>1년간<br />지원</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </v-carousel-item>
      <v-carousel-item>
        <div ref="slide2" class="max-w-1200-wrap slide-2-wrap">
          <div class="max-w-1200">
            <div class="slide slide-2">
              <div>
                <h2 class="slide-title">
                  구축비용 없이<br />
                  사용할수록 <strong>비용절감</strong> 효과!
                </h2>
                <p class="slide-desc">
                  데이터 및 네트워크 사용 용량에 제한 없이<br />
                  필수 기본 기능들이 모두 무료!<br />
                  업계 최저 수수료로 비용을 절감해 보세요!<br />
                  <strong>*영세 기준 최저 수수료</strong> <em>1.6%</em>
                </p>
              </div>
              <img
                src="../../../assets/home-new/main-1.png"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </v-carousel-item>
      <v-carousel-item>
        <div ref="slide3" class="max-w-1200-wrap slide-3-wrap">
          <div class="max-w-1200">
            <div class="slide slide-3">
              <div>
                <h2 class="slide-title">
                  지금 가입해서<br />
                  <strong>혜택 받자!</strong>
                </h2>
                <p class="slide-desc">
                  <strong>30만원</strong> 가량의 포인트 지급<br />
                  SMS(15,000건), 알림톡(30,000건) <strong>무료!</strong><br />
                  가입 후 <strong>1년간 보증보험비</strong> 환급금 포인트를
                  받아보세요.
                </p>
              </div>
              <img
                src="../../../assets/home-new/main-2.png"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </v-carousel-item>
      <v-carousel-item>
        <div ref="slide4" class="max-w-1200-wrap slide-4-wrap">
          <div class="max-w-1200">
            <div class="slide slide-4">
              <div>
                <h2 class="slide-title">
                  결제링크로 모든<br />
                  결제는 <strong>쉽고 빠르게!</strong>
                </h2>
                <p class="slide-desc">
                  필수 입력만으로 1분 내 상품 등록과 결제를 하고<br />
                  더욱 간편하게 수기 결제 주문서를 만들어보세요!
                </p>
              </div>
              <img
                src="../../../assets/home-new/main-3.png"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
    <div class="max-w-1200-wrap">
      <div class="max-w-1200">
        <div class="slide-controls">
          <button type="button" @click="back">
            <img
              src="../../../assets/home-new/chevron-right-green.svg"
              alt=""
              loading="lazy"
            />
          </button>
          <button type="button" @click="next">
            <img
              src="../../../assets/home-new/chevron-right-green.svg"
              alt=""
              loading="lazy"
            />
          </button>
          <div class="progress-wrap">
            <div class="progress">
              <div :style="`transform: scaleX(${(model + 1) * 25}%)`"></div>
            </div>
            <span>{{ model + 1 }}/4</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
export default {
  data() {
    return {
      model: 0,
    };
  },
  mounted() {
    gsap.to(this.$refs.container.querySelector(".slide-1 .slide-title"), {
      opacity: 1,
      y: 0,
      duration: 0.5,
      delay: 0.3,
    });
  },
  methods: {
    onSlide() {
      gsap.set(
        this.$refs.container.querySelector(
          `.slide-${(this.model % 4) + 1} .slide-title`
        ),
        {
          opacity: 0,
          y: 50,
        }
      );
      setTimeout(() => {
        gsap.fromTo(
          this.$refs.container.querySelector(
            `.slide-${(this.model % 4) + 1} .slide-title`
          ),
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration: 0.5 }
        );
      }, 300);
    },
    back() {
      if (!this.model) {
        return;
      }
      this.model--;

      this.onSlide();
    },
    next() {
      this.model++;

      this.onSlide();
    },
  },
};
</script>

<style scoped lang="scss">
.renew {
  .section {
    position: relative;
  }

  .v-carousel {
    .max-w-1200-wrap {
      height: 780px;
      // min-height: 780px;
      overflow: hidden;
      padding-top: 80px;
      background-size: cover;
    }

    .max-w-1200 {
      position: relative;
      height: 100%;
    }
  }

  .slide {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .slide > div {
    width: 100%;
  }

  .slide-1-wrap {
    background-image: url("../../../assets/home-new/bg-1.jpg");
  }

  .slide-2-wrap {
    background-image: url("../../../assets/home-new/bg-2.jpg");
  }

  .slide-3-wrap {
    background-image: url("../../../assets/home-new/bg-3.jpg");
  }

  .slide-4-wrap {
    background-image: url("../../../assets/home-new/bg-4.jpg");
  }

  .slide-title {
    color: #424242;
    font-weight: 300;
    font-size: 48px;
    line-height: 55px;
    margin-bottom: 24px;
    opacity: 0;
    transform: translateY(50px);
  }

  .slide-title strong {
    color: #00aba9;
  }

  .slide-desc {
    color: #424242;
    font-size: 20px;
  }

  .slide .slide-title {
    margin-top: -77px;
  }

  .slide-1 .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    margin-top: -6px;
  }

  .slide-1 .grid li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    width: 276px;
    height: 251px;
    padding-top: 20px;
    padding-bottom: 25px;
    color: #fff;
    text-align: center;
  }

  .slide-1 .grid li img {
    width: 48px;
    height: 48px;
  }

  .slide-1 .grid li p {
    font-size: 24px;
  }

  .slide-1 .grid li span {
    font-size: 48px;
    line-height: 1.1;
    font-weight: bold;
  }

  .slide-1 .grid li:first-child span {
    font-size: 32px;
    display: inline-block;
    margin-top: -3px;
  }

  .slide-1 .grid li:first-child span strong {
    font-size: 60px;
  }

  .slide-1 .grid li:first-child {
    background-color: #6a4dd3;
  }

  .slide-1 .grid li:nth-child(2) {
    background-color: #4866da;
  }

  .slide-1 .grid li:nth-child(3) {
    background-color: #448dea;
  }

  .slide-1 .grid li:nth-child(4) {
    background-color: #3dacdc;
  }

  .slide-controls {
    position: absolute;
    bottom: 113px;
    display: flex;
    align-items: center;
    z-index: 1;
  }

  .slide-controls button {
    display: inline-flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #00aba9;
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }

  .slide-controls button img {
    width: 40px;
    height: 40px;
  }

  .slide-controls button:nth-child(2) {
    margin-left: 20px;
  }

  .slide-controls button:nth-child(2) img {
    transform: rotateZ(180deg) translateX(-10%);
  }

  .progress-wrap {
    display: flex;
    align-items: center;
    margin-left: 32px;
  }

  .progress {
    position: relative;
    height: 3px;
    width: 340px;
    background-color: #d2d2d2;
    margin-right: 20px;
  }

  .progress > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: scaleX(25%);
    transform-origin: left;
    background-color: #00aba9;
    height: 3px;
    transition: 0.3s;
  }

  .progress-wrap span {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #424242;
  }

  .slide img {
    height: auto;
  }

  .slide-desc em {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    color: #00aba9;
  }

  .slide-2 img {
    margin-top: -15px;
    width: 522px;
  }

  .slide-3 img {
    margin-top: -4px;
    width: 503px;
  }

  .slide-4 img {
    margin-top: 2px;
    width: 516px;
  }

  @media screen and (max-width: 1312px) {
    .v-carousel {
      .max-w-1200-wrap {
        padding-top: 6.098vw;
        height: 59.451vw;
      }
    }

    .slide-title {
      font-size: 3.659vw;
      line-height: 4.192vw;
      margin-bottom: 1.829vw;
    }

    .slide-desc {
      font-size: 1.524vw;
    }

    .slide-1-wrap {
      background-image: url("../../../assets/home-new/bg-sm-1.jpg");
    }

    .slide-2-wrap {
      background-image: url("../../../assets/home-new/bg-sm-2.jpg");
    }

    .slide-3-wrap {
      background-image: url("../../../assets/home-new/bg-sm-3.jpg");
    }

    .slide-4-wrap {
      background-image: url("../../../assets/home-new/bg-sm-4.jpg");
    }

    .slide-1 .slide-title {
      margin-top: -5.869vw;
    }

    .slide-1 .grid {
      gap: 2.439vw;
      margin-top: -0.457vw;
    }

    .slide-1 .grid li {
      border-radius: 2.287vw;
      width: 21.037vw;
      height: 19.131vw;
      padding-top: 1.524vw;
      padding-bottom: 1.905vw;
    }

    .slide-1 .grid li img {
      width: 3.659vw;
      height: 3.659vw;
    }

    .slide-1 .grid li p {
      font-size: 1.829vw;
    }

    .slide-1 .grid li span {
      font-size: 3.659vw;
    }

    .slide-1 .grid li:first-child span {
      font-size: 2.439vw;
      margin-top: -0.229vw;
    }

    .slide-1 .grid li:first-child span strong {
      font-size: 4.573vw;
    }

    .slide-controls {
      bottom: 8.613vw;
    }

    .slide-controls button {
      width: 4.573vw;
      height: 4.573vw;
    }

    .slide-controls button img {
      width: 3.049vw;
      height: 3.049vw;
    }

    .slide-controls button:nth-child(2) {
      margin-left: 1.524vw;
    }

    .progress-wrap {
      margin-left: 2.439vw;
    }

    .progress {
      width: 25.915vw;
      margin-right: 1.524vw;
    }

    .progress-wrap span {
      font-size: 1.22vw;
      line-height: 1.372vw;
    }

    .slide-2 img {
      margin-top: -1.143vw;
      width: 39.787vw;
    }

    .slide-3 img {
      margin-top: -0.305vw;
      width: 38.338vw;
    }

    .slide-4 img {
      margin-top: 0.152vw;
      width: 39.329vw;
    }
  }

  @media screen and (max-width: 650px) {
    .v-carousel {
      .max-w-1200-wrap {
        padding-top: calc(63px + 11.733vw);
        height: calc(152vw + 63px);
      }
    }

    .slide-title {
      font-size: 6.4vw;
      line-height: 7.467vw;
      margin-bottom: 3.2vw;
    }

    .slide-desc {
      font-size: 3.2vw;
    }

    .slide {
      flex-direction: column;
      justify-content: flex-start;
      padding-bottom: 22.133vw;
    }

    .slide .slide-title {
      margin-top: 0;
    }

    .slide-1 .grid {
      gap: 4.267vw;
      margin-top: auto;
      margin-bottom: auto;
    }

    .slide-1 .grid li {
      border-radius: 4.267vw;
      width: 36.8vw;
      height: 33.467vw;
      padding-top: 2.667vw;
      padding-bottom: 3.6vw;
    }

    .slide-1 .grid li img {
      width: 6.4vw;
      height: 6.4vw;
    }

    .slide-1 .grid li p {
      font-size: 3.2vw;
    }

    .slide-1 .grid li span {
      font-size: 6.4vw;
    }

    .slide-1 .grid li:first-child span {
      font-size: 4.267vw;
      margin-top: 0.8vw;
    }

    .slide-1 .grid li:first-child span strong {
      font-size: 8vw;
    }

    .slide-controls {
      position: absolute;
      bottom: 5.6vw;
      width: calc(100% - 8vw - 4.2667vw - 4.2667vw);
      margin-left: 4vw;
      margin-right: 4vw;
      margin-top: auto;
    }

    .slide-controls button {
      width: 9.6vw;
      height: 9.6vw;
    }

    .slide-controls button img {
      width: 6.4vw;
      height: 6.4vw;
    }

    .slide-controls button:nth-child(2) {
      margin-left: 2.667vw;
    }

    .progress-wrap {
      margin-left: 2.667vw;
      flex: 1;
    }

    .progress {
      flex: 1;
      width: auto;
      margin-right: 2.133vw;
    }

    .progress-wrap span {
      font-size: 3.2vw;
      line-height: 3.733vw;
    }

    .slide img {
      margin-top: auto;
      margin-bottom: auto;
    }

    .slide-2 img {
      width: 69.6vw;
    }

    .slide-3 img {
      width: 67.067vw;
    }

    .slide-4 img {
      width: 68.8vw;
    }
  }
}
</style>